<template>
  <div class="page-control">
    <Report />
  </div>
</template>

<script>
import Report from './comp/report/report.vue'
export default {
  name: 'control',
  components: { Report },
  data() {
    return {}
  },
  methods: {},
  computed: {},
  watch: {},
  async created() {}
}
</script>
<style lang="scss">
@import './styles';
</style>
