<template>
  <Control v-if="isAdmin" />
</template>

<script>
import { mapGetters } from 'vuex'
import { GETTER_APP } from '@/store/const/type'
import Control from './control'
export default {
  name: 'index-control',
  components: { Control },
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters({ isAdmin: GETTER_APP.IS_ADMIN })
  },
  watch: {},
  async created() {}
}
</script>
