<template>
  <div class="report-ctrl">
    <span>Отчет прошедших трансляций:</span>
    <div class="page-ctrl-data">
      <span>от:</span>
      <mts-date-picker
        v-model="fromDate"
        placeholder="дд.мм.гггг"
        prefix-icon="mts-icon-calendar"
        label="Выберите дату"
        required
        clearable
        style="margin-right: 16px"
      />
      <span>до:</span>
      <mts-date-picker
        v-model="toDate"
        placeholder="дд.мм.гггг"
        prefix-icon="mts-icon-calendar"
        label="Выберите дату"
        required
        clearable
      />
      <button @click.prevent="onClick">
        <Download />
        <span>Отчет в Excel</span>
      </button>
    </div>
  </div>
</template>

<script>
import Download from '@/pic/svg/download.vue'
import { postAxiosReportStream } from '@/views/control/func'
export default {
  name: 'control',
  components: { Download },
  data() {
    return {
      fromDate: new Date(),
      toDate: new Date()
    }
  },
  methods: {
    async onClick() {
      await postAxiosReportStream(this.fromDate.toISOString(), this.toDate.toTimeString())
    }
  },
  computed: {},
  watch: {},
  async created() {}
}
</script>
<style lang="scss">
@import './styles';
</style>
